import React, { useCallback, useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Card,
  Col,
  Divider,
  Image,
  Input,
  Layout,
  Menu,
  MenuProps,
  message,
  QRCode,
  Row,
  Segmented,
  Skeleton,
  Space,
  Spin,
  Typography,
  Upload,
  UploadFile,
} from "antd";
import {
  CopyOutlined,
  InboxOutlined,
  QrcodeOutlined,
  UploadOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useAppDispatch, useAppSelector } from "./store/hooks";
import { startPeer, stopPeerSession } from "./store/peer/peerActions";
import * as connectionAction from "./store/connection/connectionActions";
import { DataType, PeerConnection } from "./helpers/peer";
import { useAsyncState } from "./helpers/hooks";
import Constants from "./helpers/constants";
import { Content } from "antd/es/layout/layout";
import * as globalAction from "./store/global/globalActions";
import { RcFile } from "antd/es/upload";

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: "group"
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

export const App: React.FC = () => {
  const peer = useAppSelector((state) => state.peer);
  const connection = useAppSelector((state) => state.connection);
  const global = useAppSelector((state) => state.global);
  const dispatch = useAppDispatch();
  const [backgroundVideoUrl, setBackgroundVideoUrl] = useState("");
  const [dropzoneVisible, setDropzoneVisible] = useState(false);

  const handleStartSession = () => {
    dispatch(startPeer());
  };

  const handleStopSession = async () => {
    await PeerConnection.closePeerSession();
    dispatch(stopPeerSession());
  };

  const handleConnectOtherPeer = () => {
    console.log("handleConnectOtherPeer", connection.id);
    if (connection.list.length >= Constants.MAX_CONNECTION_COUNT) {
      message.warning(
        `You can connect max ${Constants.MAX_CONNECTION_COUNT} users`
      );
      return;
    }

    if (connection.id) {
      dispatch(connectionAction.connectPeer(connection.id || ""));
      dispatch(connectionAction.changeConnectionInput(""));
    } else {
      message.warning("Please enter ID");
    }
  };

  const [fileList, setFileList] = useState([] as UploadFile[]);

  const handleUpload = async () => {
    if (fileList.length === 0) {
      message.warning("Please select file");
      return;
    }

    if (!connection.selectedId) {
      message.warning("Please select a connection");
      return;
    }

    try {
      dispatch(globalAction.setLoading(true));
      for (let i = 0; i < fileList.length; i++) {
        let file = fileList[i] as unknown as UploadFile;
        if (!(file.originFileObj instanceof File)) {
          file.originFileObj = file.originFileObj as unknown as RcFile;
        }
        // console.log("GÖNDERİLEN DOSYA", file, file instanceof File);
        // let blob = new Blob([file.originFileObj], { type: file.type });

        await PeerConnection.sendConnection(connection.selectedId, {
          dataType: DataType.FILE,
          file: await file.originFileObj?.arrayBuffer(),
          fileName: file.name,
          fileType: file.type,
        });
      }
      dispatch(globalAction.setLoading(false));
      message.info("Send file(s) successfully");
    } catch (err) {
      dispatch(globalAction.setLoading(false));
      console.log(err);
      message.error("Error when sending file");
    }
  };

  const prepareQrData = useCallback(() => {
    return window.location.origin + "/?id=" + peer.id;
  }, [peer.id]);

  const setBackground = () => {
    // /background-video/video1/720p.mp4
    // ekran boyutuna göre video seçimi yapılabilir
    const backgrounds = [
      "video1",
      "video2",
      "video3",
      "video4",
      "video5",
      "video6",
      "video7",
    ];
    const randomBackgroundIndex = Math.floor(
      Math.random() * backgrounds.length
    );
    const randomBackground = backgrounds[randomBackgroundIndex];

    const BASE_PATH = "https://d18rm7882id3w5.cloudfront.net/";

    if (window.innerWidth < 540) {
      setBackgroundVideoUrl(
        `${BASE_PATH}background-video/${randomBackground}/360p.mp4`
      );
    } else if (window.innerWidth < 720) {
      setBackgroundVideoUrl(
        `${BASE_PATH}background-video/${randomBackground}/540p.mp4`
      );
    } else if (window.innerWidth < 1080) {
      setBackgroundVideoUrl(
        `${BASE_PATH}background-video/${randomBackground}/720p.mp4`
      );
    } else {
      setBackgroundVideoUrl(
        `${BASE_PATH}background-video/${randomBackground}/1080p.mp4`
      );
    }
  };

  const startListener = () => {
    window.addEventListener("dragenter", (e) => {
      e.preventDefault();
      setDropzoneVisible(true);
    });
    window.addEventListener("dragleave", (e) => {
      e.preventDefault();
      setDropzoneVisible(false);
    });
    window.addEventListener("dragover", (e) => {
      e.preventDefault();
      setDropzoneVisible(true);
    });
    window.addEventListener("drop", (e) => {
      e.preventDefault();
      setDropzoneVisible(false);

      if (fileList.length >= Constants.MAX_FILE_COUNT) {
        message.warning(`You can upload max ${Constants.MAX_FILE_COUNT} files`);
        return;
      }

      console.log("%c drop", "color: red", "---------", fileList.length);

      if (e.dataTransfer && e.dataTransfer.files) {
        if (
          e.dataTransfer.files.length + fileList.length >
          Constants.MAX_FILE_COUNT
        ) {
          message.warning(
            `You can upload max ${Constants.MAX_FILE_COUNT} files`
          );

          setFileList([
            ...fileList,
            ...(Array.from(e.dataTransfer.files).slice(
              0,
              Constants.MAX_FILE_COUNT - fileList.length
            ) as unknown as UploadFile[]),
          ] as unknown as UploadFile[]);
        }

        if (e.dataTransfer.files.length > Constants.MAX_FILE_COUNT) {
          message.warning(
            `You can upload max ${Constants.MAX_FILE_COUNT} files`
          );

          setFileList([
            ...fileList,
            ...(Array.from(e.dataTransfer.files).slice(
              0,
              Constants.MAX_FILE_COUNT - fileList.length
            ) as unknown as UploadFile[]),
          ] as unknown as UploadFile[]);

          return;
        }

        const files = Array.from(e.dataTransfer.files).slice(
          0,
          Constants.MAX_FILE_COUNT - fileList.length
        ) as unknown as UploadFile[];

        files.map((file) => {
          if (!file.originFileObj) {
            file.originFileObj = file as unknown as RcFile;
          }
          return file;
        });

        setFileList([
          ...fileList,
          ...(Array.from(e.dataTransfer.files) as unknown as UploadFile[]),
        ] as unknown as UploadFile[]);
        console.log("%c drop", "color: red", "---------", fileList);
      }
    });
  };

  useEffect(() => {
    handleStartSession();

    setBackground();

    startListener();

    return () => {
      handleStopSession();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <video
        autoPlay
        muted
        loop
        playsInline
        style={styles.videoBackground}
        src={backgroundVideoUrl}
      ></video>
      <Spin
        tip={
          <Typography.Text style={{ color: "black", fontSize: "24px" }}>
            Loading... Please wait...
          </Typography.Text>
        }
        spinning={global.loading}
        size="large"
        style={styles.spinWrapper}
      >
        <Layout
          style={{ background: "transparent", width: "100vw", height: "100vh" }}
        >
          <Content style={{ padding: "16px" }}>
            <Row
              align={"top"}
              style={{
                marginTop: 16,
              }}
              gutter={[16, 16]}
            >
              <Col
                xs={24}
                sm={12}
                md={10}
                lg={8}
                xl={6}
                style={{ margin: "auto" }}
              >
                <Card style={styles.glassmorphismCard}>
                  <Row
                    justify={"center"}
                    style={{
                      paddingTop: 16,
                      paddingBottom: 16,
                    }}
                    gutter={[16, 16]}
                  >
                    <Col xs={"auto"} style={{ textAlign: "center" }}>
                      <Image
                        src={"/logo512.png"}
                        preview={false}
                        height={64}
                        width={225}
                      />
                    </Col>
                    <Col
                      xs={24}
                      style={{ textAlign: "center" }}
                      hidden={!peer.started}
                    >
                      {!peer.started ? (
                        <Skeleton.Node
                          active
                          style={{
                            width: 164,
                            height: 164,
                            borderRadius: 12,
                          }}
                        >
                          <QrcodeOutlined
                            style={{ fontSize: 64, color: "#bfbfbf" }}
                          />
                        </Skeleton.Node>
                      ) : (
                        <center>
                          <QRCode
                            errorLevel="H"
                            size={164}
                            iconSize={164 / 4}
                            value={prepareQrData()}
                            icon="/wepeer-amblem.svg"
                            includeMargin={true}
                            style={styles.glassmorphismInnerCard}
                          />
                        </center>
                      )}
                      {/* <Image
                  style={{
                    borderRadius: 24,
                    display: peer.started ? "block" : "none",
                  }}
                  width={164}
                  src={
                    "https://chart.googleapis.com/chart?chs=400x400&cht=qr&chl=" +
                    prepareQrData()
                  }
                /> */}
                    </Col>
                    <Col>
                      <Row
                        hidden={!peer.started}
                        justify={"center"}
                        gutter={[16, 16]}
                      >
                        <Col xs={24}>
                          <Button
                            block
                            type="dashed"
                            size="large"
                            icon={<CopyOutlined />}
                            onClick={async () => {
                              await navigator.clipboard.writeText(
                                peer.id || ""
                              );
                              message.info("Copied: " + peer.id);
                            }}
                          >
                            <Divider
                              type="vertical"
                              style={{ marginLeft: 0 }}
                            />
                            <Space size={2}>
                              <span>Your ID:</span> <strong>{peer.id}</strong>
                            </Space>
                          </Button>
                        </Col>
                        <Col xs={24}>
                          <Row gutter={[8, 8]}>
                            <Col xs={14}>
                              <Input
                                placeholder={"Other ID"}
                                onChange={(e) => {
                                  dispatch(
                                    connectionAction.changeConnectionInput(
                                      e.target.value
                                    )
                                  );
                                }}
                                required={true}
                                size="large"
                                type="number"
                                value={connection.id}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    handleConnectOtherPeer();
                                  }
                                }}
                              />
                            </Col>
                            <Col xs={10}>
                              <Button
                                block
                                size="large"
                                type="primary"
                                onClick={handleConnectOtherPeer}
                                loading={connection.loading}
                              >
                                Connect
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={24} style={{ textAlign: "center" }}>
                      <Row justify={"center"}>
                        <Col xs={24}>
                          <Typography.Text type="secondary">
                            from
                          </Typography.Text>
                        </Col>
                        <Col
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "end",
                            textAlign: "right",
                          }}
                        >
                          <Image
                            src={"/codextra-logo-black.png"}
                            preview={false}
                            height={37}
                            width={113}
                            onClick={() => {
                              window.open("https://codextra.co.uk", "_blank");
                            }}
                            style={styles.kodgarajLogo}
                          />
                        </Col>
                        <Col xs={24}>
                          {/* versiyon */}
                          <Typography.Text type="secondary">
                            v0.2.1
                          </Typography.Text>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card>
              </Col>
              {peer.started && connection.list.length > 0 && (
                <Col xs={24} sm={12} md={14} lg={16} xl={18}>
                  <Row gutter={[16, 16]}>
                    <Col xs={24} lg={12}>
                      <Card style={styles.glassmorphismCard}>
                        <Row gutter={[16, 16]}>
                          <Col xs={24}>
                            <Row gutter={[16, 8]}>
                              <Col
                                xs={24}
                                style={{
                                  paddingLeft: "12px",
                                  paddingRight: "12px",
                                }}
                              >
                                <Row align={"middle"}>
                                  <Typography.Text
                                    style={{
                                      fontSize: "24px",
                                      fontWeight: 300,
                                    }}
                                  >
                                    Connections ({connection.list.length}/
                                    {Constants.MAX_CONNECTION_COUNT})
                                  </Typography.Text>
                                </Row>
                              </Col>
                              <Col xs={24}>
                                <Segmented
                                  options={connection.list.map((peer) => ({
                                    label: (
                                      <div style={{ padding: 4 }}>
                                        <Avatar
                                          style={{
                                            backgroundColor: peer.color,
                                          }}
                                          icon={<UserOutlined />}
                                          size={{
                                            xs: 32,
                                            md: 40,
                                            lg: 64,
                                            xl: 80,
                                            xxl: 100,
                                          }}
                                        />
                                        <div>
                                          <Typography.Text
                                            style={{
                                              fontSize: "16px",
                                              fontWeight: 500,
                                            }}
                                          >
                                            {peer.id}
                                          </Typography.Text>
                                        </div>
                                      </div>
                                    ),
                                    value: peer.id,
                                  }))}
                                  value={connection.selectedId}
                                  onChange={(id) => {
                                    console.log("item", id);
                                    const peerId = id as string;
                                    dispatch(
                                      connectionAction.selectItem(peerId)
                                    );
                                  }}
                                />
                                {/* <Menu
                              selectedKeys={
                                connection.selectedId
                                  ? [connection.selectedId]
                                  : []
                              }
                              onSelect={(item) =>
                                dispatch(connectionAction.selectItem(item.key))
                              }
                              items={connection.list.map((e) =>
                                getItem(e, e, null)
                              )}
                              style={{
                                marginTop: 0,
                                background: "transparent",
                              }}
                            /> */}
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Card>
                    </Col>

                    <Col xs={24} lg={12}>
                      <Card
                        style={{
                          ...styles.glassmorphismCard,
                          marginBottom: 24,
                        }}
                      >
                        <Row gutter={[16, 16]}>
                          <Col
                            xs={24}
                            style={{
                              paddingLeft: "12px",
                              paddingRight: "12px",
                            }}
                          >
                            <Row justify={"space-between"} align={"middle"}>
                              <Typography.Text
                                style={{
                                  fontSize: "24px",
                                  fontWeight: 300,
                                }}
                              >
                                Files ({fileList.length}/
                                {Constants.MAX_FILE_COUNT})
                              </Typography.Text>
                              <Button
                                type="primary"
                                onClick={handleUpload}
                                disabled={fileList.length === 0}
                                loading={global.loading}
                                style={{ color: "white" }}
                              >
                                {global.loading ? "Sending" : "Send"}
                              </Button>
                              {/* <Button
                                type="primary"
                                onClick={() => {
                                  // dispatch(connectionAction.clearList());
                                }}
                                disabled={connection.list.length === 0}
                                loading={connection.loading}
                                style={{ color: "white" }}
                              >
                                Clear
                              </Button> */}
                            </Row>
                          </Col>
                          <Col xs={24}>
                            <Upload.Dragger
                              height={132}
                              fileList={fileList}
                              maxCount={Constants.MAX_FILE_COUNT}
                              onRemove={(file) => {
                                setFileList(
                                  fileList.filter(
                                    (e: any) => e.uid !== file.uid
                                  ) as unknown as UploadFile[]
                                );
                              }}
                              beforeUpload={() => false}
                              onChange={(info) => {
                                console.log("info", info);
                                setFileList([
                                  ...(info.fileList as unknown as UploadFile[]),
                                ] as unknown as UploadFile[]);
                                console.log(
                                  "%c beforeUpload",
                                  "color: red",
                                  "---------",
                                  fileList
                                );
                              }}
                              multiple={true}
                            >
                              <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                              </p>
                              {/* <p className="ant-upload-text"></p> */}
                              <p className="ant-upload-hint">
                                Click or drag file to this area to upload
                              </p>
                            </Upload.Dragger>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              )}
            </Row>
          </Content>
        </Layout>
      </Spin>
      <div
        style={{
          ...styles.fullWindowDropzone,
          zIndex: dropzoneVisible ? 99999 : -1,
          opacity: dropzoneVisible ? 1 : 0,
        }}
      >
        <Row>
          <Col>
            <span style={styles.fullWindowDropzoneText}>
              Please drop file to this area to upload
            </span>
          </Col>
        </Row>
      </div>
    </>
  );
};

const styles: any = {
  videoBackground: {
    width: "100vw",
    height: "100vh",
    objectFit: "cover",
    position: "fixed",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: -1,
  },
  glassmorphismCard: {
    // backgroundColor: "rgba(255, 255, 255, 0.2)",
    // backdropFilter: "blur(10px)",
    // border: "1px solid rgba(255, 255, 255, 0.2)",
    // borderRadius: 10,
    background: "rgba(255, 255, 255, 0.45)",
    borderRadius: "24px",
    border: "0",
    boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
    backdropFilter: "blur(8.2px)",
    WebkitBackdropFilter: "blur(8.2px)",
  },
  glassmorphismInnerCard: {
    background: "rgb(15, 15, 249, 0.05)",
    border: "0",
    boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
    backdropFilter: "blur(.3px)",
    WebkitBackdropFilter: "blur(.3px)",
  },
  // glassmorphismButton: {
  //   background: "rgb(199, 0, 57, 0.8)",
  //   color: "white",
  //   border: "0",
  //   boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
  //   backdropFilter: "blur(.3px)",
  //   "WebkitBackdropFilter": "blur(.3px)",
  // },
  fullWindowDropzone: {
    position: "fixed",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: -1,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    color: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 32,
    textAlign: "center",
    transition: "all 0.2s ease-in-out",
  },
  fullWindowDropzoneText: {
    fontSize: "2em",
  },
  kodgarajLogo: {
    cursor: "pointer",
  },
};

export default App;
